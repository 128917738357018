import { graphql, useStaticQuery } from 'gatsby';
import localeFilter from '@lce/intl-util/src/localeFilter';
import { ISiteProps, ILocaleProps } from '@lce/intl-types/src/IDatoLocale';
import { Direction } from '@lce/intl-types/src/IDirection';
import { IDatoImage } from '@lce/intl-types/src/IDatoImage';
import { IInstruction } from '@lce/intl-types/src/IInstruction';
import franchiseeDirection from '@lce/intl-util/src/FranchiseeDirection';
import { PanelLayout } from '@lce/intl-types/src/ILayout';

import { IDatoFranchiseeNodes } from '../FranchiseeId/types';
import { useSelectedFranchiseeId } from '../FranchiseeId';
import useFranchisees from '../FranchiseeId/hooks/useFranchisees';

export interface IDirectionProps {
  direction: Direction;
}

export interface ISiteConfigProps extends ISiteProps {
  siteConfig: {
    nodes: ISiteConfigDefaultProps[];
  };
  franchisee: IDatoFranchiseeNodes;
}

interface ISiteConfigDefaultProps extends ILocaleProps {
  isFullWidthLayout: boolean;
  isThreePanel: boolean;
  googleMapKey: string;
  message404: string;
  buttonText404: string;
  buttonLink404: string;
  image404: IDatoImage;
  headingImage: IDatoImage;
  instructions: IInstruction[];
  panelLayout: PanelLayout;
}

export interface ISiteConfig {
  object404: {
    buttonLink: string;
    buttonText: string;
    image: IDatoImage;
    message: string;
  };
  direction: Direction;
  siteConfig: {
    isFullWidthLayout: boolean;
    isThreePanel: boolean;
    googleMapKey: string;
    panelLayout: PanelLayout;
  };
  landingPage: {
    headingImage: IDatoImage;
    instructions: IInstruction[];
  };
}

// eslint-disable-next-line max-lines-per-function
const useSiteConfig = (locale: string) => {
  const data: ISiteConfigProps = useStaticQuery(graphql`
    query SiteConfigMsQuery {
      datoCmsSite {
        locale
      }
      siteConfig: allDatoCmsSiteConfig {
        nodes {
          panelLayout
          isThreePanel
          isRtl
          isFullWidthLayout
          locale
          googleMapKey
          image404 {
            alt
            url
          }
          message404
          buttonText404
          buttonLink404
          headingImage {
            url
            alt
          }
          instructions {
            heading
            subheading
          }
        }
      }
      franchisee: allDatoCmsFranchisee {
        nodes {
          customTheme
          originalId
          isactive
          isRtl
          locale
        }
      }
    }
  `);
  const { franchiseeId: SelectedFranchiseeId } = useSelectedFranchiseeId(locale);
  const { franchisees } = useFranchisees(locale);

  const direction: Direction = franchiseeDirection(franchisees, SelectedFranchiseeId);

  const filteredSiteConfig = localeFilter<ISiteConfigDefaultProps>(data?.siteConfig?.nodes, locale);


  const siteConfig: ISiteConfig = {
    object404: {
      buttonLink: filteredSiteConfig?.buttonLink404,
      buttonText: filteredSiteConfig?.buttonText404,
      image: filteredSiteConfig?.image404,
      message: filteredSiteConfig?.message404,
    },
    direction,
    siteConfig: {
      isFullWidthLayout:filteredSiteConfig?.isFullWidthLayout,
      isThreePanel: filteredSiteConfig?.isThreePanel,
      googleMapKey: filteredSiteConfig?.googleMapKey,
      panelLayout: filteredSiteConfig?.panelLayout,
    },
    landingPage: {
      headingImage: filteredSiteConfig?.headingImage,
      instructions: filteredSiteConfig?.instructions,
    },
  };

  return siteConfig;
};

export default useSiteConfig;
